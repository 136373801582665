<div class="container-page">
    <mat-card class="container-card">
      <div class="card-content">
        <div class="text-not-found">
            404         
        </div>
        <div class="card-content">
            <span class="card-title">Page not found :(</span>
            <p>Maybe the page you are looking for has been removed, or you typed in the wrong URL</p>        
        </div>
      </div>
    </mat-card>
</div>
